import { Typography } from "@material-ui/core";
import React from "react";
import {
  List,
  Filter,
  SelectInput,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import { Conditionally } from "../components/conditionally";
import { MobileListGrid } from "../components/mobile-list-grid";

export const ListDistricts = ({ permissions, ...props }) => (
  <List
    {...props}
    bulkActionButtons={false}
    sort={{ field: "name", order: "ASC" }}
    filters={<Filters />}
  >
    <MobileListGrid
      content={record => (
        <>
          <Typography variant="body1">{record.name}</Typography>
          <Typography variant="caption" color="primary">
            {record.level?.name}
            {record.parent ? <> in {record.parent?.name}</> : ""}
          </Typography>
        </>
      )}
      link={record => `/districts/${record.id}/show`}
    />
  </List>
);

const Filters = props => {
  return (
    <Filter {...props}>
      <ReferenceInput
        source="levelId"
        reference="levels"
        variant="outlined"
        label="Level"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        source="parentId"
        reference="districts"
        variant="outlined"
        label="Parent"
        filterToQuery={filterToLocationQuery}
      >
        <AutocompleteInput
          option
          optionText={<LocationOptionRenderer />}
          matchSuggestion={matchLocationSuggestion}
          inputText={locationInputText}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const LocationOptionRenderer = ({ record: choice, width }) => {
  return (
    <div style={{ width }}>
      <Typography variant="caption">{choice?.name}</Typography>
      <Conditionally
        when={choice?.parent}
        render={() => (
          <div>
            <Typography variant="caption" color="textSecondary">
              {choice.level.name} in {choice.parent.name}
            </Typography>
          </div>
        )}
      />
    </div>
  );
};

export function locationInputText(choice) {
  return choice?.name;
}

export const matchLocationSuggestion = () => true;

export function filterToLocationQuery(searchText) {
  return {
    search: { name: searchText }
  };
}
