import { users, profileEdit } from "./users";
import facilities from "./facilities";
import machines, {
  machineCalibrations,
  machineLogs,
  relayLogs,
} from "./machines";
import results, { cartridges, requests } from "./results";
import dashboard from "./dashboard";
import districts from "./districts";
import inventoryItems from "./inventory/items";
import {
  facilityAdmin,
  admin,
  superAdmin,
  dho,
  stakeholder,
  maintenance,
} from "./roles";
import tests from "./tests";
import { timelines } from "./timelines";
import { inventoryStockAdjustment } from "./inventory";
import { inventoryBatches, inventoryLevels } from "./inventory/batches";
import { levels } from "./levels";
import { cases } from "./cases";
import { maintenanceTickets } from "./maintenance";
import facilityAssignments from "./facility-assignment";
import { analyses } from "./analyses";
import { mergeAll } from "ramda";
import { notifications } from "./notifications";

export function getResources(user) {
  const resources = resourceMapper[user?.role];

  return [canManage("users", "user"), canManage("facility-assignments")].reduce(
    (acc, fn) => fn(acc, user),
    resources || []
  );
}

function canManage(resourceName, permissionName = resourceName) {
  return (resources, user) => {
    const hasPermission =
      user?.permissions?.[permissionName]?.includes("manage");
    return resources.filter((resource) => {
      return resource.name === resourceName ? hasPermission : true;
    });
  };
}

const superAdminResources = [
  results,
  requests,
  users,
  timelines,
  facilities,
  machines,
  machineLogs,
  machineCalibrations,
  maintenanceTickets,
  relayLogs,
  districts,
  levels,
  tests,
  cases,
  inventoryItems,
  inventoryStockAdjustment,
  inventoryBatches,
  inventoryLevels,
  cartridges,
  facilityAssignments,
  { name: "reports/dashboards" },
  { name: "settings" },
  { name: "versions" },
  analyses,
  notifications
];

console.log(
  mergeAll(superAdminResources.map((r) => ({ [r.name]: { name: r.name } })))
);

const adminResources = [
  results,
  requests,
  users,
  timelines,
  facilities,
  machines,
  machineLogs,
  machineCalibrations,
  maintenanceTickets,
  districts,
  levels,
  cases,
  tests,
  facilityAssignments,
  inventoryItems,
  inventoryStockAdjustment,
  inventoryBatches,
  inventoryLevels,
  cartridges,
  analyses,
];

const stakeHolderResources = [dashboard];

const facilityAdminResources = [
  results,
  requests,
  machines,
  machineLogs,
  machineCalibrations,
  maintenanceTickets,
  users,
  cases,
  inventoryItems,
  inventoryStockAdjustment,
  inventoryBatches,
  inventoryLevels,
  cartridges,
  { name: "facilities" },
  { name: "timelines" },
];

const dhoResources = [
  dashboard,
  results,
  requests,
  facilities,
  machines,
  machineLogs,
  machineCalibrations,
  maintenanceTickets,
  users,
  districts,
  { name: "levels" },
  { ...inventoryItems, create: null },
  inventoryStockAdjustment,
  inventoryLevels,
  inventoryBatches,
  profileEdit,
  cartridges,
  { name: "timelines" },
];

const maintenanceResources = [
  machines,
  machineCalibrations,
  machineLogs,
  maintenanceTickets,
  { ...users, list: null },
  { ...facilities, list: null },
  cartridges,
  { name: "timelines" },
];

const resourceMapper = {
  [superAdmin]: superAdminResources,
  [admin]: adminResources,
  [stakeholder]: stakeHolderResources,
  [facilityAdmin]: facilityAdminResources,
  [dho]: dhoResources,
  [maintenance]: maintenanceResources,
};
