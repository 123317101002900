import React from "react";
import {
  UserMenu as RaUserMenu,
  MenuItemLink,
  usePermissions,
  useLogout,
} from "react-admin";
import { GetApp, PowerOff, Settings } from "@material-ui/icons";

import { DownloadDump } from "../components/db-dump-btn";
import { Conditionally } from "../components/conditionally";
import { superAdmin } from "../roles";
import { relayUrl } from "../config";
import { Bullboard } from "../components/bullboard";

export const UserMenu = (props) => {
  const { permissions: user } = usePermissions();

  return (
    <RaUserMenu {...props}>
      <Conditionally
        when={user?.role === superAdmin}
        render={() => <DownloadDump />}
      />
      <Conditionally
        when={user?.role === superAdmin}
        render={() => <Bullboard />}
      />
      <DownloadRelay />
      <MenuItemLink
        to={`/users/${user?.id}/edit?profile`}
        primaryText="Profile"
        leftIcon={<Settings />}
      />
      <LogoutButton />
    </RaUserMenu>
  );
};

const LogoutButton = () => {
  const logout = useLogout();
  return (
    <MenuItemLink
      leftIcon={<PowerOff />}
      to="/logout"
      primaryText="Logout"
      onClick={() => logout()}
    />
  );
};

export const DownloadRelay = () => {
  function handleDownload(e) {
    e.preventDefault();
    window.open(relayUrl, "_blank");
  }

  return (
    <MenuItemLink
      leftIcon={<GetApp />}
      to="/download-relay"
      primaryText="Download Relay"
      onClick={handleDownload}
    />
  );
};
