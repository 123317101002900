import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { DynamicFeed, Warning } from "@material-ui/icons";
import React, { cloneElement } from "react";
import {
  List,
  Pagination as RaPagination,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
  ExportButton,
  CreateButton,
  Button,
  TextInput,
  BooleanInput,
  SelectArrayInput,
} from "react-admin";
import { Link } from "react-router-dom";
import { Conditionally } from "../components/conditionally";
import { MobileListGrid } from "../components/mobile-list-grid";
import { SortButton } from "../components/sort";
import {
  filterToLocationQuery,
  locationInputText,
  LocationOptionRenderer,
  matchLocationSuggestion,
} from "../districts/list";
import { pluralise } from "../utils/number";
import { facilityTypes } from "./constants";

export const ListFacility = (props) => {
  return (
    <List
      title="Facilities"
      bulkActionButtons={false}
      perPage={50}
      sort={{ field: "name", order: "ASC" }}
      pagination={<Pagination />}
      filters={<Filters />}
      actions={<ListActions />}
      filterDefaultValues={{ type: ["Testing Center", "Treatment Center"] }}
      {...props}
    >
      <MobileListGrid
        metadata={(record) => (
          <Grid container direction="row" spacing={1}>
            {record.type?.map((type) => (
              <Grid item>
                <Chip
                  label={type}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
            ))}
          </Grid>
        )}
        content={(record) => (
          <>
            <Typography variant="body1">{record.name}</Typography>
            <Typography variant="caption" color="primary">
              {record.district?.name}
            </Typography>
            <Grid container spacing={1} component={Box} marginTop={1}>
              <Conditionally
                when={record.instruments}
                render={() => (
                  <Grid item>
                    <Chip
                      label={pluralise(record.instruments, `instrument`)}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                )}
              />
              <Conditionally
                when={record.installedModules}
                render={() => (
                  <Grid item>
                    <Chip
                      label={`${record.installedModules} modules`}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                )}
              />
              <Conditionally
                when={record.installedModules - record.functionalModules}
                render={() => (
                  <Grid item>
                    <Chip
                      label={`${
                        record.installedModules - record.functionalModules
                      } disabled modules`}
                      size="small"
                      variant="outlined"
                    />
                  </Grid>
                )}
              />
            </Grid>
          </>
        )}
        right={(record) => (
          <Conditionally
            when={record.needsAttention}
            render={() => (
              <Grid item>
                <Chip
                  icon={<Warning />}
                  label={"Needs Attention"}
                  size="small"
                  color="primary"
                />
              </Grid>
            )}
          />
        )}
        link={(record) => `/facilities/${record.id}/show`}
      />
    </List>
  );
};

const Pagination = (props) => (
  <RaPagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ListActions = ({ filters }) => (
  <TopToolbar>
    <Grid container spacing={1}>
      <Grid item>{cloneElement(filters, { context: "button" })}</Grid>

      <Grid item>
        <SortButton
          fields={[{ id: "name", name: "Name" }]}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <ExportButton variant="outlined" />
      </Grid>
      <Grid item>
        <Button
          component={Link}
          to={"/bulk/facilities"}
          variant="outlined"
          label="Bulk Edit"
        >
          <DynamicFeed />
        </Button>
      </Grid>
      <Grid item>
        <CreateButton variant="contained" />
      </Grid>
    </Grid>
  </TopToolbar>
);

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectArrayInput
        source="type"
        variant="outlined"
        choices={facilityTypes}
      />
      <TextInput label="Name" source="search.name" variant="outlined" />
      <BooleanInput label="Needs Attention" source="needsAttention" />
      <ReferenceInput
        source="districtId"
        reference="districts"
        variant="outlined"
        label="Location"
        filterToQuery={filterToLocationQuery}
      >
        <AutocompleteInput
          optionText={<LocationOptionRenderer />}
          matchSuggestion={matchLocationSuggestion}
          inputText={locationInputText}
        />
      </ReferenceInput>
    </Filter>
  );
};
