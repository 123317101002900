import * as React from "react";
import { LoadingIndicator, usePermissions } from "react-admin";
import { Helmet } from "react-helmet";
import { SidebarToggleButton } from "ra-ui-materialui/lib/layout/SidebarToggleButton";
import { Typography, AppBar, Toolbar, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpOutline, Settings } from "@material-ui/icons";

import { UserMenu } from "../profile/menu";
import { PushNotificationsPrompt } from "../components/push-notifications-prompt";
import { Link, useLocation } from "react-router-dom";
import { Conditionally } from "../components/conditionally";
import { superAdmin } from "../roles";
import { LanguageSwitcher } from "../i18n/switcher";
import { useSettings } from "../settings";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 0.8,
  },
});

export const MyAppBar = () => {
  const [title, setTitle] = React.useState("");
  const { permissions: user } = usePermissions();
  const settings = useSettings();
  const classes = useStyles();
  const appbarRef = React.useRef();
  const location = useLocation();
  
  React.useEffect(() => {
    setTitle(document.querySelector("#react-admin-title").textContent);
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{title ? title + " | " : ""}{settings["instance-name"]}</title>
      </Helmet>
      <AppBar
        color="secondary"
        ref={appbarRef}
        sx={{
          "& .RaAppBar-title": {
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          },
        }}
      >
        <Toolbar variant="dense" disableGutters>
          <SidebarToggleButton />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
          <LanguageSwitcher />
          <LoadingIndicator />

          <IconButton
            href={`https://labxpertet.tawk.help/`}
            target="_blank"
            color="inherit"
          >
            <HelpOutline />
          </IconButton>

          <Conditionally
            when={user?.role === superAdmin}
            render={() => (
              <IconButton component={Link} to={"/settings"} color="inherit">
                <Settings />
              </IconButton>
            )}
          />
          <UserMenu />
        </Toolbar>
      </AppBar>
      <PushNotificationsPrompt />
    </>
  );
};
