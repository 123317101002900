import { useLocale, useSetLocale } from "react-admin";
import { Box, Menu, MenuItem, Typography } from "@material-ui/core";
import { availableLocales } from "../i18n";
import React, { useState } from "react";

export const LanguageSwitcher = () => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(false);
  }

  function handleSelect(value) {
    setLocale(value);
    handleClose();
  }

  return (
    <>
      <Box
        onClick={handleClick}
        paddingX={1}
        paddingY={"2px"}
        style={{ background: "#fff4", cursor: "pointer" }}
      >
        <Typography>{locale.toUpperCase()}</Typography>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {availableLocales.map((l) => (
          <MenuItem onClick={() => handleSelect(l.locale)}>{l.name}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
