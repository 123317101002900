import React from "react";
import { Route } from "react-router-dom";
import { ForgotPassword } from "./auth/forgot";
import { ResetPassword } from "./auth/reset";
import { BulkEditFacilities } from "./facilities/assign-facilities";
import { BulkEditMachines } from "./machines/assign-machines";
import { Settings } from "./settings";
import { LoginWithPasscode } from "./auth/passcode";

export const customRoutes = [
  <Route exact path="/forgot" component={ForgotPassword} noLayout />,
  <Route path="/reset/:token" component={ResetPassword} noLayout />,
  <Route path="/passcode/:code" component={LoginWithPasscode} noLayout />,
  <Route exact path="/bulk/facilities" component={BulkEditFacilities} />,
  <Route exact path="/bulk/machines" component={BulkEditMachines} />,
  <Route exact path="/settings" component={Settings} />,
];
