import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { client } from "../providers/client";
import { vapidPublicKey } from "../config";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  }
}));

export const PushNotificationsPrompt = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    subscribeToPush().then(subscription => {
      if (!subscription) return;
      return client
        .post("/notifications/check-subscription", {
          subscription
        })
        .then(response => {
          setIsLoading(false);
          if (response.data) {
            setIsSubscribed(true);
          } else {
            setIsSubscribed(false);
          }
        });
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = async () => {
    setOpen(false);
    if (navigator.serviceWorker) {
      subscribeToPush().then(subscription => {
        return client
          .post("/notifications/subscribe", {
            subscription
          })
          .then(() =>
            Promise.all([
              navigator.serviceWorker.ready.then(registration =>
                registration.showNotification("Push Enabled", {
                  body: "You'll now receive push notifications from LabXpert",
                  icon: "images/icon.png"
                })
              ),
              Notification.requestPermission()
            ])
          );
      });
    }
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      {isSubscribed ? null : (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={open}
          autoHideDuration={10000}
          onClose={handleClose}
          message="Enable Push Notification from LabXpert"
          action={
            <React.Fragment>
              <Button color="primary" size="medium" onClick={handleClick}>
                Enable
              </Button>
            </React.Fragment>
          }
        />
      )}
    </div>
  );
};

export function subscribeToPush() {
  if (navigator.serviceWorker) {
    return getWorkerRegistration().then(registration =>
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
      })
    );
  }

  return Promise.resolve();
}

function getWorkerRegistration() {
  if (navigator.serviceWorker) {
    return navigator.serviceWorker.ready.then(registration => registration);
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
