import React from "react";
import { Chip } from "@material-ui/core";

import Created from "@material-ui/icons/AddToPhotos";
import AwaitingSample from "@material-ui/icons/TransferWithinAStation";
import AwaitingTransit from "@material-ui/icons/CallReceived";
import AwaitingTests from "@material-ui/icons/HourglassEmpty";
import InProgress from "@material-ui/icons/Autorenew";
import AwaitingReview from "@material-ui/icons/RateReview";
import Rejected from "@material-ui/icons/Close";
import Completed from "@material-ui/icons/Done";
import Released from "@material-ui/icons/DoneAll";
import Referred from "@material-ui/icons/Forward";
import Updated from "@material-ui/icons/Edit";
import Dispatch from "@material-ui/icons/Telegram";
import Pending from "@material-ui/icons/Build";
import {
  Block,
  CloudDownload,
  Email,
  Money as AwaitingPayment,
  PersonAdd as AwaitingAssignee,
  Timer as Ready,
  VerifiedUser,
  WhatsApp,
  Add as StockIn,
  Remove as StockOut,
  Equalizer as StockUpdate,
  DeleteForever,
} from "@material-ui/icons";

const mapper = {
  "awaiting-sample": {
    icon: AwaitingSample,
    color: "#ffbf00",
    textColor: "rgba(0, 0, 0, 0.54)",
    label: "Awaiting Sample",
  },
  "awaiting-transit": {
    icon: AwaitingTransit,
    color: "darkorange",
    label: "In Transit",
  },
  "in-progress": {
    icon: InProgress,
    color: "blue",
    label: "In Progress",
  },
  "awaiting-tests": {
    icon: AwaitingTests,
    color: "blue",
    label: "Awaiting Tests",
  },
  "awaiting-treatment": {
    icon: AwaitingTests,
    color: "orange",
    label: "Awaiting Treatment",
  },
  "started-treatment": {
    icon: InProgress,
    color: "blue",
    label: "Started Treatment",
  },
  "completed-treatment": {
    icon: Completed,
    color: "green",
    label: "Completed",
  },
  "awaiting-repeat": {
    icon: AwaitingTests,
    color: "blue",
    label: "Awaiting Repeat",
  },
  "awaiting-review": {
    icon: AwaitingReview,
    color: "#014d4e",
    label: "Awaiting Review",
  },
  "awaiting-release": {
    icon: Dispatch,
    color: "teal",
    label: "Awaiting Release",
  },
  rejected: {
    icon: Rejected,
    color: "red",
    label: "Rejected",
  },
  invalidated: {
    icon: Rejected,
    color: "red",
    label: "Invalidated",
  },
  cancelled: {
    icon: Rejected,
    color: "gray",
    label: "Cancelled",
  },
  initiated: {
    icon: AwaitingTests,
    color: "red",
    label: "Initiated",
  },
  pending: {
    icon: Pending,
    color: "darkorange",
    label: "Pending",
  },
  completed: {
    icon: Completed,
    color: "green",
    label: "Completed",
  },
  closed: {
    icon: Released,
    color: "grey",
    label: "Closed",
  },
  referred: {
    icon: Referred,
    color: "purple",
    label: "Referred",
  },
  released: {
    icon: Released,
    color: "green",
    label: "Released",
  },
  passed: {
    icon: Released,
    color: "green",
    label: "Passed",
  },
  approved: {
    icon: Released,
    color: "green",
    label: "Approved",
  },
  created: {
    icon: Created,
    color: "#ccc",
    label: "Created",
  },
  "awaiting-payment": {
    icon: AwaitingPayment,
    color: "#ffbf00",
    textColor: "rgba(0, 0, 0, 0.54)",
    label: "Awaiting Payment",
  },
  "awaiting-assignee": {
    icon: AwaitingAssignee,
    color: "darkorange",
    label: "Awaiting Assignee",
  },
  ready: {
    icon: Ready,
    color: "blue",
    label: "Ready",
  },
  success: {
    icon: VerifiedUser,
    color: "green",
    label: "Sent",
  },
  fail: {
    icon: Block,
    color: "red",
    label: "Failed",
  },
  failed: {
    icon: Block,
    color: "red",
    label: "Failed",
  },
  whatsapp: {
    icon: WhatsApp,
    color: "green",
    label: "WhatsApp",
  },
  email: {
    icon: Email,
    color: "red",
    label: "Email",
  },
  downloaded: {
    icon: CloudDownload,
    color: "green",
    label: "Downloaded",
  },
  deleted: {
    icon: Rejected,
    color: "red",
    label: "Cancelled",
  },
  updated: {
    icon: Updated,
    color: "#ccc",
    label: "Updated",
  },
  in: {
    icon: StockIn,
    color: "green",
    label: "Restocked",
  },
  out: {
    icon: StockOut,
    color: "red",
    label: "Stock Issued",
  },
  consumption: {
    icon: StockOut,
    color: "red",
    label: "Autoconsumed",
  },
  update: {
    icon: StockUpdate,
    color: "purple",
    label: "Recounted",
  },
  dispose: {
    icon: DeleteForever,
    color: "purple",
    label: "Disposed",
  },
};

export const StatusField = ({
  record = {},
  source = "status",
  defaultLabel,
  size,
}) => {
  const {
    label,
    color,
    textColor,
    icon: Icon,
  } = mapper[record[source]] || mapper.released;

  return (
    <Chip
      label={defaultLabel || label}
      color="primary"
      size={size}
      style={{ backgroundColor: color, color: textColor }}
      icon={<Icon />}
    />
  );
};

export const StatusIcon = ({ status, ...props }) => {
  const { icon: Icon, color } = mapper[status] || mapper.released;
  return <Icon style={{ color }} />;
};
