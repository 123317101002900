import * as React from "react";
import { Menu, makeStyles, Typography } from "@material-ui/core";
import { MenuItemLink, useTranslate } from "react-admin";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles({
  listItem: {
    padding: "4px 0",
  },
  listItemText: {
    paddingLeft: "4px",
    color: "#7F7C82",
    fontSize: "48px",
    letterSpacing: "inherit",
  },
  ListItemIcon: { paddingLeft: "20px" },
  popMenu: {
    marginLeft: "15vh",
  },
});

export function DesktopMenu({ menuResources, icon, name }) {
  const translate = useTranslate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItem
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        dense
        button
        component="div"
        className={classes.listItem}
      >
        <ListItemIcon className={classes.ListItemIcon}>{icon}</ListItemIcon>

        <ListItemText className={classes.listItemText}>
          <Typography variant="body1">{name}</Typography>
        </ListItemText>
      </ListItem>

      <Menu
        id="basic-menu"
        className={classes.popMenu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuResources.map((resource) => (
          <MenuItemLink
            key={resource.name}
            onClick={handleClose}
            to={`/${resource.name}`}
            primaryText={translate(`resources.${resource.name}.name`)}
            leftIcon={<resource.icon />}
          />
        ))}
      </Menu>
    </div>
  );
}

export default DesktopMenu;
