import React from "react";
import {
  Typography,
  Grid,
  IconButton,
  Select,
  MenuItem,
  TextField,
  FormControl,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";

import { Conditionally } from "./conditionally";

export const Controls = () => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <TimeRangePicker
          type="current"
          unitOfTime="day"
          onChange={() => null}
        />
      </Grid>
    </Grid>
  );
};

export const UnitOfTimePicker = ({ value, onChange }) => {
  const handleChange = (event) => {
    const unitOfTime = event.target.value;
    onChange(unitOfTime);
  };
  return (
    <FormControl size="small" variant="standard">
      <Select onChange={handleChange} value={value} size="small">
        <MenuItem value="day">Days</MenuItem>
        <MenuItem value="week">Weeks</MenuItem>
        <MenuItem value="month">Months</MenuItem>
        <MenuItem value="quarter">Quarter</MenuItem>
        <MenuItem value="year">Year</MenuItem>
      </Select>
    </FormControl>
  );
};

export const TimeRangePicker = ({ value, onChange }) => {
  function handleChange(change) {
    onChange({ ...value, ...change });
  }

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <FormControl size="small" variant="standard">
          <Select
            onChange={(e) => handleChange({ type: e.target.value })}
            value={value.type}
          >
            <MenuItem value="after">After</MenuItem>
            <MenuItem value="before">Before</MenuItem>
            <MenuItem value="between">Btn</MenuItem>
            <MenuItem value="current">This</MenuItem>
            <MenuItem value="last">Last</MenuItem>
            <MenuItem value="exact">Exact</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Conditionally
        when={["last"].includes(value.type)}
        render={() => (
          <Grid item>
            <TextField
              variant="standard"
              size="small"
              type="number"
              style={{ width: 48, textAlign: "right" }}
              min={0}
              value={value.qty}
              onChange={(e) => handleChange({ qty: e.target.value })}
            />
          </Grid>
        )}
      />

      <Conditionally
        when={["current", "last", "exact"].includes(value.type)}
        render={() => (
          <Grid item>
            <FormControl size="small" variant="standard">
              <Select
                onChange={(e) => handleChange({ unitOfTime: e.target.value })}
                value={value.unitOfTime}
                size="small"
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
                <MenuItem value="quarter">Quarter</MenuItem>
                <MenuItem value="year">Year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      />

      <Conditionally
        when={["between", "before"].includes(value.type)}
        render={() => (
          <Grid item>
            <DatePicker
              inputVariant="standard"
              labelFunc={(day) => day?.format("Do MMM YY")}
              disableFuture
              fullWidth
              value={value.before}
              size="small"
              onChange={(date) => handleChange({ before: date.toDate() })}
              style={{ width: "96px" }}
            />
          </Grid>
        )}
      />

      <Conditionally
        when={["between", "after"].includes(value.type)}
        render={() => (
          <Grid item>
            <DatePicker
              inputVariant="standard"
              labelFunc={(day) => day?.format("Do MMM YY")}
              disableFuture
              fullWidth
              value={value.after}
              size="small"
              onChange={(date) => handleChange({ after: date.toDate() })}
              style={{ width: "96px" }}
            />
          </Grid>
        )}
      />

      <Conditionally
        when={["exact"].includes(value.type)}
        render={() => (
          <Grid item>
            <ExactPicker
              value={value.date || new Date()}
              unitOfTime={value.unitOfTime}
              onChange={(date) => handleChange({ date: new Date(date) })}
            />
          </Grid>
        )}
      />
    </Grid>
  );
};

const ExactPicker = (props) => {
  const { value, unitOfTime, onChange } = props;
  const disableNext = moment(value)
    .endOf(unitOfTime)
    .add(1, unitOfTime)
    .isAfter(moment().endOf(unitOfTime));
  const handleChange = (delta) => () => {
    const date = moment(value).add(parseInt(delta), unitOfTime).toDate();
    onChange(date);
  };
  return (
    <Grid container alignItems="center">
      <Grid item>
        <IconButton size="small" onClick={handleChange(-1)}>
          <ChevronLeft />
        </IconButton>
      </Grid>

      <Grid item>
        <Typography variant="body2">
          {formatExact(props.value, props.unitOfTime)}
        </Typography>
      </Grid>

      <Grid item>
        <IconButton
          size="small"
          disabled={disableNext}
          onClick={handleChange(1)}
        >
          <ChevronRight />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export function formatExact(date = new Date(), unitOfTime = "day") {
  const value = moment(date);
  if (unitOfTime === "day") {
    return value.endOf(unitOfTime).format("Do MMM YYYY");
  }

  if (unitOfTime === "month") {
    return value.format("MMMM");
  }

  if (unitOfTime === "year") {
    return value.format("YYYY");
  }

  if (unitOfTime === "quarter") {
    return (
      value.startOf(unitOfTime).format("MMM") +
      " - " +
      value.endOf(unitOfTime).format("MMM YYYY")
    );
  }

  return (
    value.startOf(unitOfTime).format("Do MMM") +
    " - " +
    value.endOf(unitOfTime).format("Do MMM YYYY")
  );
}
