import { Chip, Grid, Typography } from "@material-ui/core";
import React, { cloneElement } from "react";
import { isNil } from "ramda";
import {
  Filter,
  List,
  Pagination as RaPagination,
  ReferenceInput,
  TextInput,
  AutocompleteInput,
  TopToolbar,
  ExportButton,
  Button,
  SelectInput,
} from "react-admin";

import { Conditionally } from "../components/conditionally";
import { DateRangeInput } from "../components/date-range";
import {
  getStatus,
  getStatusColor,
  MachineStatus,
} from "../components/machine-grid";
import { MobileListGrid } from "../components/mobile-list-grid";
import {
  filterToLocationQuery,
  locationInputText,
  LocationOptionRenderer,
  matchLocationSuggestion,
} from "../districts/list";
import { DynamicFeed } from "@material-ui/icons";
import { SortButton } from "../components/sort";
import { Link } from "react-router-dom";
import { RegisterTrueNat } from "./truenat";

export const ListMachines = ({ permissions: user, ...props }) => (
  <List
    {...props}
    filters={<Filters />}
    title="Equipment"
    sort={{ field: "lastSeenAt", order: "DESC" }}
    perPage={50}
    pagination={<Pagination />}
    actions={<ListActions />}
    bulkActionButtons={false}
  >
    <MachineListGrid />
  </List>
);

const ListActions = ({ filters }) => (
  <TopToolbar>
    <Grid container spacing={1}>
      <Grid item>{cloneElement(filters, { context: "button" })}</Grid>

      <Grid item>
        <SortButton
          fields={[
            { id: "name", name: "Name" },
            { id: "lastSeenAt", name: "Last Seen" },
          ]}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <ExportButton variant="outlined" />
      </Grid>
      <Grid item>
        <Button
          component={Link}
          to={"/bulk/machines"}
          variant="outlined"
          label="Bulk Edit"
        >
          <DynamicFeed />
        </Button>
      </Grid>
      <Grid item>
        <RegisterTrueNat />
      </Grid>
    </Grid>
  </TopToolbar>
);

export const MachineListGrid = ({
  ids,
  data,
  onSelect,
  selectedIds,
  hasBulkActions,
}) => {
  return (
    <MobileListGrid
      ids={ids}
      data={data}
      metadata={(record) => (
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Chip color="primary" label={record.source} size="small" />
          </Grid>

          <Conditionally
            when={!isNil(record.relayVersion)}
            render={() => {
              return (
                <Grid item>
                  <Chip
                    color="primary"
                    label={record.relayVersion}
                    size="small"
                  />
                </Grid>
              );
            }}
          />

          <Conditionally
            when={!isNil(record.truenatStatus)}
            render={() => {
              return (
                <Grid item>
                  <Chip
                    color="primary"
                    label={record.truenatStatus}
                    size="small"
                  />
                </Grid>
              );
            }}
          />

          <Conditionally
            when={record.genexpert}
            render={() => (
              <Grid item>
                <Chip
                  label={`${Object.keys(record.genexpert.sites).length} module`}
                  size="small"
                />
              </Grid>
            )}
          />

          <Conditionally
            when={!isNil(record.lastCompletedAt)}
            render={() => (
              <Grid item>
                <Chip
                  style={{
                    background: getStatusColor(record.lastCompletedAt),
                    color: "white",
                  }}
                  label={`Last test ${getStatus(record.lastCompletedAt)} ago`}
                  size="small"
                />
              </Grid>
            )}
          />
        </Grid>
      )}
      content={(record) => (
        <>
          <Typography variant="body1">{record.lab?.name}</Typography>
          <Grid container style={{ paddingTop: "4px" }} spacing={1}>
            {record.serialNumbers.map((serial) => (
              <Grid key={serial} item>
                <Chip label={serial} size="small" />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      right={(record) => (
        <Conditionally
          when={record.truenatStatus !== "initiated"}
          render={() => <MachineStatus record={record} label="status" />}
        />
      )}
      link={(record) => `/machines/${record.id}/show`}
      hasBulkActions={hasBulkActions}
      onSelect={onSelect}
      selectedIds={selectedIds}
    />
  );
};

const Filters = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        source="source"
        label="Type"
        choices={[
          { id: "relay", name: "GeneXpert" },
          { id: "truenat", name: "TrueNat" },
        ]}
      />
      <TextInput source="search.name" label="Name" />
      <TextInput source="serialNumbers" label="Instrument" />
      <TextInput label="GeneXpert Version" source="genexpert.version" />

      <ReferenceInput
        source="facilityId"
        reference="facilities"
        filterToQuery={(name) => ({ search: { name } })}
        variant="outlined"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="districtId"
        reference="districts"
        label={"Location"}
        variant="outlined"
        filterToQuery={filterToLocationQuery}
      >
        <AutocompleteInput
          inputText={locationInputText}
          optionText={<LocationOptionRenderer />}
          matchSuggestion={matchLocationSuggestion}
        />
      </ReferenceInput>
      <DateRangeInput source="dates.createdAt" label="First Seen" />
      <DateRangeInput source="dates.lastSeenAt" label="Last Seen" />
      <DateRangeInput source="dates.lastUploadedAt" label="Last Uploaded" />
    </Filter>
  );
};

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100, 200, 500, 1000]}
    perPage={50}
    {...props}
  />
);
