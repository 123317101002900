import React, { Fragment } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = {
  listItem: {
    padding: 0,
  },
  listItemText: {
    paddingLeft: "3px",
    fontSize: "0.0em",
  },
  ListItemIcon: { paddingLeft: "20px" },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
};

const MobileMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  classes,
  children,
}) => (
  <Fragment>
    <ListItem
      dense
      button
      component="div"
      onClick={handleToggle}
      className={classes.listItem}
    >
      <ListItemIcon className={classes.ListItemIcon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>

      <ListItemText
        primary={isOpen ? <Typography variant="body1">{name}</Typography> : ""}
        secondary={
          isOpen ? (
            ""
          ) : (
            <Typography variant="body1" color="textSecondary">
              {name}
            </Typography>
          )
        }
        className={classes.listItemText}
      />
    </ListItem>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List
        dense
        component="div"
        disablePadding
        className={
          sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
        }
      >
        {children}
      </List>
      <Divider />
    </Collapse>
  </Fragment>
);

export default withStyles(styles)(MobileMenu);
