import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DashboardMenuItem, MenuItemLink, usePermissions, useTranslate } from "react-admin";
import { Box, useMediaQuery } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";

import { getResources } from "../resources";
import { users } from "../users";
import facilities from "../facilities";
import districts from "../districts";
import machines from "../machines";
import results from "../results";
import DesktopMenu from "./desktop";
import MobileMenu from "./mobile";
import items from "../inventory/items";
import { inventoryBatches } from "../inventory/batches";
import { levels } from "../levels";
import { Conditionally } from "../components/conditionally";
import { cases } from "../cases";
import { maintenanceTickets } from "../maintenance";
import { timelines } from "../timelines";
import facilityAssignments from "../facility-assignment";
import { analyses } from "../analyses";
import { notifications } from "../notifications";

const DisMenuPresenter = ({ onMenuClick, open }) => {
  const translate = useTranslate();
  const { permissions: user } = usePermissions();
  const resources = getResources(user);
  const isDesktop = useMediaQuery("(min-width:600px)");

  const filter = (items) =>
    items
      .filter((resource) => resources.some((r) => r.name === resource.name))
      .filter((resource) =>
        resources.some((r) => r.name === resource.name && r.list)
      );

  const menuResources = filter([
    facilities,
    machines,
    maintenanceTickets,
    results,
    cases,
    analyses,
    inventoryBatches,
    items,
    users,
  ]);

  const settingsResources = filter([
    levels,
    districts,
    timelines,
    facilityAssignments,
    notifications
  ]);

  return (
    <Box marginTop={2}>
      <DashboardMenuItem onClick={onMenuClick} />
      {menuResources.map((resource) => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.name`)}
          leftIcon={<resource.icon />}
        />
      ))}
      <Conditionally
        when={isDesktop && settingsResources.length}
        render={() => (
          <DesktopMenu
            menuResources={settingsResources}
            sidebarIsOpen={open}
            name="More"
            icon={<MoreHoriz />}
          />
        )}
      />

      <Conditionally
        when={!isDesktop && settingsResources.length}
        render={() => (
          <MobileMenu sidebarIsOpen={open} name="More" icon={<MoreHoriz />}>
            {settingsResources.map((resource) => (
              <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={translate(`resources.${resource.name}.name`)}
                leftIcon={<resource.icon />}
              />
            ))}
          </MobileMenu>
        )}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
});

export const Menu = withRouter(connect(mapStateToProps)(DisMenuPresenter));
