import React from "react";
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  required,
  AutocompleteInput,
  BooleanInput,
  CheckboxGroupInput,
  email,
} from "react-admin";
import {
  roles,
  facilityAdmin,
  dho,
  admin,
  superAdmin,
  stakeholder,
} from "../roles";
import {
  assignmentPermissions,
  machinePermissions,
  maintenancePermissions,
  resultPermissions,
  treatmentPermissions,
  userPermissions,
} from "./constants";
import { CameraInput } from "../components/camera";
import { Conditionally } from "../components/conditionally";
import {
  filterToLocationQuery,
  locationInputText,
  LocationOptionRenderer,
  matchLocationSuggestion,
} from "../districts/list";

export const UserCreate = (props) => {
  return (
    <Create {...props}>
      <Form {...props} />
    </Create>
  );
};

export const UserEdit = (props) => {
  return (
    <Edit title="Edit User" {...props}>
      <Form {...props} />
    </Edit>
  );
};

export const Form = ({ permissions: user, ...props }) => {
  return (
    <SimpleForm {...props} redirect={false}>
      <FormDataConsumer>
        {({ formData }) => {
          if (user?.id === formData.id) {
            return <CameraInput source="avatar" />;
          }
        }}
      </FormDataConsumer>

      <TextInput
        variant="outlined"
        source="name"
        fullWidth
        validate={[required()]}
      />

      <TextInput
        variant="outlined"
        type="email"
        source="email"
        fullWidth
        validate={[email("Must be a valid email")]}
      />

      <TextInput
        variant="outlined"
        source="phone"
        validate={[required()]}
        fullWidth
      />

      <Conditionally
        when={[superAdmin].includes(user?.role)}
        render={() => (
          <SelectInput
            variant="outlined"
            source="role"
            choices={roles}
            fullWidth
            validate={[required()]}
          />
        )}
      />

      <Conditionally
        when={[admin].includes(user?.role)}
        render={() => (
          <SelectInput
            variant="outlined"
            source="role"
            choices={roles.filter((role) => ![superAdmin].includes(role.id))}
            fullWidth
            validate={[required()]}
          />
        )}
      />

      <Conditionally
        when={[dho].includes(user?.role)}
        render={() => (
          <SelectInput
            variant="outlined"
            source="role"
            choices={roles.filter(
              (role) => ![superAdmin, admin].includes(role.id)
            )}
            fullWidth
            validate={[required()]}
          />
        )}
      />

      <Conditionally
        when={[facilityAdmin].includes(user?.role)}
        render={() => (
          <SelectInput
            variant="outlined"
            source="role"
            choices={roles.filter(
              (role) => ![superAdmin, admin, dho, stakeholder].includes(role.id)
            )}
            fullWidth
            validate={[required()]}
          />
        )}
      />

      <FormDataConsumer>
        {({ formData: { role } }) => {
          if (![admin, superAdmin, dho].includes(user?.role)) {
            return null;
          }

          if ([facilityAdmin].includes(role)) {
            return (
              <ReferenceInput
                variant="outlined"
                label="Lab"
                source="facilityId"
                reference="facilities"
                filterToQuery={(name) => ({ search: { name } })}
                validate={[required()]}
                fullWidth
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            );
          }

          if (role === dho) {
            return (
              <ReferenceInput
                variant="outlined"
                label="Area"
                source="districtId"
                reference="districts"
                fullWidth
                filter={{}}
                perPage={100}
                InputLabelProps={{
                  shrink: true,
                }}
                filterToQuery={filterToLocationQuery}
                validate={[required()]}
              >
                <AutocompleteInput
                  options={{
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  optionText={<LocationOptionRenderer />}
                  matchSuggestion={matchLocationSuggestion}
                  inputText={locationInputText}
                />
              </ReferenceInput>
            );
          }
        }}
      </FormDataConsumer>

      <Conditionally
        when={user?.permissions?.user?.includes("suspend")}
        render={() => <BooleanInput source="suspended" />}
      />

      <Conditionally
        when={user?.permissions?.user?.includes("permissions")}
        render={() => (
          <CheckboxGroupInput
            source="permissions.user"
            label="User Permissions"
            choices={userPermissions}
          />
        )}
      />

      <Conditionally
        when={
          user?.permissions?.user?.includes("permissions") &&
          user?.role === superAdmin
        }
        render={() => (
          <CheckboxGroupInput
            source="permissions.machine"
            label="Machine Permissions"
            choices={machinePermissions}
          />
        )}
      />

      <Conditionally
        when={user?.permissions?.user?.includes("permissions")}
        render={() => (
          <CheckboxGroupInput
            source="permissions.maintenance"
            label="Maintenance Permissions"
            choices={maintenancePermissions}
          />
        )}
      />

      <Conditionally
        when={
          user?.permissions?.user?.includes("permissions") &&
          user?.role === superAdmin
        }
        render={() => (
          <CheckboxGroupInput
            source="permissions.result"
            label="Result Permissions"
            choices={resultPermissions}
          />
        )}
      />

      <Conditionally
        when={user?.permissions?.user?.includes("permissions")}
        render={() => (
          <CheckboxGroupInput
            source="permissions.treatment"
            label="Treatment Case Permissions"
            choices={treatmentPermissions}
          />
        )}
      />

      <Conditionally
        when={
          user?.permissions?.user?.includes("permissions") &&
          user?.role === superAdmin
        }
        render={() => (
          <CheckboxGroupInput
            source="permissions[facility-assignments]"
            label="Master List Permissions"
            choices={assignmentPermissions}
          />
        )}
      />
    </SimpleForm>
  );
};
