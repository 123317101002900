import React, { useState } from "react";
import {
  refreshView,
  showNotification,
  Button,
  CreateButton,
} from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useRequestState } from "../hooks/request";
import { client } from "../providers/client";

export const RegisterTrueNat = withRouter(
  connect(null, {
    refreshView,
    showNotification,
  })(({ refreshView: refresh, showNotification: notify }) => {
    const [open, setOpen] = useState(false);
    const [serialNumber, setSerialNumber] = useState();
    const [password, setPassword] = useState();

    const saveAction = useRequestState((serial, pw) =>
      client.post("/portal/machines/truenat/register", { serial, password: pw })
    );

    function handleClose() {
      setOpen(false);
    }

    function handleSerialNumberChange(event) {
      setSerialNumber(event.target.value);
    }

    function handlePasswordChange(event) {
      setPassword(event.target.value);
    }

    function handleSave() {
      if (!serialNumber) {
        return notify("Missing reason");
      }

      return saveAction
        .fetch(serialNumber, password)
        .then(() => {
          setOpen(false);
          notify("TrueNAT Registered");
          refresh();
        })
        .catch((error) => {
          notify(error.response.data.message);
        });
    }

    return (
      <>
        <CreateButton
          variant={"contained"}
          color={"primary"}
          to={null}
          onClick={(e) => {
            e.preventDefault();
            setOpen(true);
          }}
        />

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Register TrueNAT</DialogTitle>
          <DialogContent>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <TextField
                  label="Serial Number"
                  variant="outlined"
                  fullWidth
                  value={serialNumber}
                  onChange={handleSerialNumberChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  multiline
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              label="Cancel"
              disabled={saveAction.loading}
            />
            <Button
              onClick={handleSave}
              color="primary"
              label="Save"
              disabled={saveAction.loading}
            />
          </DialogActions>
        </Dialog>
      </>
    );
  })
);
