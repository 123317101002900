import { ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";
import moment from "moment";
import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  Box,
  CardActions,
} from "@material-ui/core";
import { Telegram } from "@material-ui/icons";
import { Diagnosis } from "../components/diagnosis";
import { Breadcrumbs } from "../components/breadcrumbs";
import { FlatActionButton } from "../components/action-button";
import { Conditionally } from "../components/conditionally";
import { StatusField } from "../components/status";
import { ListEvents } from "../components/timeline";
import { client } from "../providers/client";
import { useHistory } from "react-router-dom";
import { TimestampField } from "../components/fields";
import { viewPatientDetails } from "../utils/view-patient-details";

const useStyles = makeStyles({
  root: {
    marginBottom: "16px",
  },
});

export function ShowResult(props) {
  return (
    <Show
      title={<Title />}
      actions={<Actions user={props.permissions} history={props.history} />}
      component="div"
      {...props}
    >
      <SimpleShowLayout>
        <Info userPermissions={props.permissions?.permissions?.user} />
        <Request userPermissions={props.permissions?.permissions?.user} />
        <Tests />
        <Analyte />
        <Errors />
        <Conditionally
          render={() => <ListEvents />}
          when={["super-admin", "admin"].includes(props.permissions?.role)}
        />
      </SimpleShowLayout>
    </Show>
  );
}

export const Actions = ({ data }) => {
  const history = useHistory();
  if (!data) return null;

  return (
    <Box paddingX={2}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <Breadcrumbs
            items={[
              { pathname: "/results", name: "Results" },
              {
                name: data?.instrument?.sampleId,
              },
            ]}
          />
        </Grid>

        <Grid item>
          <FlatActionButton
            title={"Open Case"}
            handler={() =>
              client
                .request({
                  url: `/portal/cases/`,
                  method: "POST",
                  data: { resultId: data.id },
                })
                .then((response) => response.data)
                .then((url) => history.push(url))
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const Tests = ({ record }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        {record?.operator && (
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="caption">Operator</Typography>
              <Typography variant="body1">{record.operator}</Typography>
            </Grid>
          </Grid>
        )}
        <Box marginTop={2} />
        <Grid container>
          {(record.diagnosis || []).map((diagnosis) => (
            <Grid key={diagnosis.name} item xs={6} sm={4}>
              <Diagnosis
                unit={diagnosis?.unit}
                name={diagnosis.name}
                quantitative={diagnosis.value.quantitative}
                qualitative={diagnosis.value.qualitative}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Request = ({ record: rawRecord, userPermissions }) => {
  const record = viewPatientDetails(userPermissions, rawRecord);

  const classes = useStyles();
  if (!record.request) {
    return null;
  }
  return (
    <Card className={classes.root}>
      <CardHeader title="Test Details" />
      <CardContent>
        <Grid container spacing={2}>
          <InfoField
            label={"Test Type"}
            value={record.request?.testType?.toUpperCase()}
          />
          <Conditionally
            when={record.request.testType === "Patient"}
            render={() => (
              <>
                <InfoField label={"Patient ID"} value={record.request?.mrn} />
                <InfoField
                  label={"Patient Name"}
                  value={record.request?.name}
                />
                <InfoField label={"Patient Age"} value={record.request?.age} />
                <InfoField
                  label={"Patient Gender"}
                  value={record.request?.gender?.toUpperCase()}
                />
                <InfoField
                  label={"Infection Site"}
                  value={record.request?.infectionSite}
                />
                <InfoField
                  label={"HIV Status"}
                  value={record.request?.hivStatus}
                />
                <InfoField
                  label={"New or Previously Treated"}
                  value={record.request.previouslyTreated}
                />
                <InfoField
                  label={"Date of Sample Collection"}
                  value={
                    record.request?.sampleCollectedAt && (
                      <TimestampField
                        source={"sampleCollectedAt"}
                        record={record.request}
                      />
                    )
                  }
                />
                <InfoField
                  label={"Sample Collection Site"}
                  value={record.request?.sampleCollectionSiteType}
                />
                <InfoField
                  label={"HIV Status"}
                  value={record.request?.sampleCollectionSite?.name}
                />
                <InfoField
                  label={"HIV Status"}
                  value={record.request?.hivStatus}
                />
                {
                  <Conditionally
                    when={userPermissions?.includes("ppi")}
                    render={() => (
                      <>
                        <InfoField
                          label={"Clinician Contact"}
                          value={record.request?.clinicianContacts}
                        />
                        <InfoField
                          label={"Patient Contact"}
                          value={record.request?.patientContacts}
                        />
                      </>
                    )}
                  />
                }
              </>
            )}
          />
          <Conditionally
            when={record.request.testType === "Panel"}
            render={() => (
              <>
                <InfoField
                  label={"Panel Provider"}
                  value={record.request?.panelProvider}
                />
                <InfoField
                  label={"Panel Scheme"}
                  value={record.request?.panelScheme}
                />
                <InfoField
                  label={"Panel Year"}
                  value={record.request?.panelyear}
                />
                <InfoField
                  label={"Panel Round"}
                  value={record.request?.panelRound}
                />
                <InfoField
                  label={"Panel PT Id"}
                  value={record.request?.panelPtId}
                />
              </>
            )}
          />
          <Conditionally
            when={record.request.testType === "Research"}
            render={() => (
              <>
                <InfoField
                  label={"Research Name"}
                  value={record.request?.researchName}
                />
                <InfoField
                  label={"Participant ID"}
                  value={record.request?.mrn}
                />
                <InfoField label={"Patient Age"} value={record.request?.age} />
                <InfoField
                  label={"Patient Gender"}
                  value={record.request?.gender?.toUpperCase()}
                />
              </>
            )}
          />
        </Grid>
      </CardContent>
      <Conditionally
        when={record.request.testType === "Patient"}
        render={() => (
          <CardActions>
            <FlatActionButton
              title={"Send Notifications"}
              handler={() => client.get(`/portal/results/${record.id}/notify`)}
              success="Notifications Scheduled"
              component="button"
              icon={<Telegram />}
            />
          </CardActions>
        )}
      />
    </Card>
  );
};

const Info = ({ record: rawRecord, userPermissions }) => {
  const record = viewPatientDetails(userPermissions, rawRecord);

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={6} md={3}>
            <StatusField record={record} />
          </Grid>

          <Conditionally
            when={record.startedAt}
            render={() => (
              <InfoField
                label="Started"
                hint={moment(record.startedAt).format("Do MMM YYYY hh:mma")}
                value={moment(record.startedAt).calendar()}
              />
            )}
          />

          <InfoField
            label="Completed"
            hint={moment(record.completedAt).format("Do MMM YYYY hh:mma")}
            value={moment(record.completedAt).calendar()}
          />

          <InfoField
            label="Uploaded"
            hint={moment(record.uploadedAt).format("Do MMM YYYY hh:mma")}
            value={moment(record.uploadedAt).calendar()}
          />

          <Conditionally
            when={record?.instrument?.cartridgeType}
            render={() => (
              <InfoField
                label="Cartridge Type"
                value={record.instrument.cartridgeType}
              />
            )}
          />

          <Conditionally
            when={record?.instrument?.cartridgeId}
            render={() => (
              <InfoField
                label="Cartridge S/N"
                value={record.instrument.cartridgeId}
              />
            )}
          />

          <Conditionally
            when={record?.instrument?.moduleSite}
            render={() => (
              <InfoField label="Site" value={record.instrument.moduleSite} />
            )}
          />

          <Conditionally
            when={record?.instrument?.expires}
            render={() => (
              <InfoField
                label="Reagent Expiration Date"
                value={moment(record.instrument.expires).calendar()}
              />
            )}
          />

          <Conditionally
            when={record.instrument.reagentLotId}
            render={() => (
              <InfoField
                label="Reagent Lot ID"
                value={record.instrument.reagentLotId}
              />
            )}
          />

          <Conditionally
            when={record.instrument.instrumentId}
            render={() => (
              <InfoField
                label="Instrument S/N"
                value={record.instrument.instrumentId}
              />
            )}
          />

          <Conditionally
            when={record.lab?.name}
            render={() => (
              <InfoField label="Facility" value={record.lab?.name} />
            )}
          />

          <InfoField
            label="Sample ID"
            value={record.instrument?.sampleId?.toUpperCase()}
          />

          <Conditionally
            when={record?.patient?.primaryId}
            render={() => (
              <InfoField label="Patient ID" value={record.patient?.primaryId} />
            )}
          />

          <Conditionally
            when={record?.rejectionReason}
            render={() => (
              <InfoField
                label="Reason for Rejection"
                value={record?.rejectionReason}
              />
            )}
          />

          <Conditionally
            when={record?.updateCount}
            render={() => (
              <InfoField
                label="Overwrite Count"
                value={record?.updateCount > 10 ? record.updateCount % 30: record.updateCount}
              />
            )}
          />

          <InfoField
            label="Machine"
            value={
              <ReferenceField
                source="machineId"
                reference="machines"
                linkType="show"
              >
                <TextField source="name" />
              </ReferenceField>
            }
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const Analyte = ({ record }) => {
  const classes = useStyles();
  if (!record.analyte) {
    return null;
  }

  const analytesKeys = Object.keys(record.analyte || {});
  const valueKeys = Object.keys(record.analyte[analytesKeys[0]]).sort((a, b) =>
    a > b ? 1 : -1
  );

  return (
    <Card className={classes.root}>
      <CardHeader title="Analyte" />

      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Analyte Name
            </TableCell>

            {valueKeys.map((x, i) => (
              <TableCell key={i} numeric>
                {x}
              </TableCell>
            ))}
          </TableRow>
          {analytesKeys.map((key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                {key}
              </TableCell>

              {valueKeys.map((x, i) => (
                <TableCell key={i} numeric>
                  {record.analyte[key][x]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

const Errors = ({ record }) => {
  const classes = useStyles();

  if (!record.errors?.length) {
    return <div />;
  }

  return (
    <Card style={{ flexGrow: 1 }} className={classes.root}>
      <CardHeader title="Errors" />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ color: "#CA0B00" }}>Code</TableCell>
            <TableCell style={{ color: "#CA0B00" }}>Details</TableCell>
            <TableCell style={{ color: "#CA0B00" }}>Severity</TableCell>
            <TableCell style={{ color: "#CA0B00" }}>Time</TableCell>
          </TableRow>
          {record?.errors?.map((error, index) => {
            const { code, details, severity, time } = error;
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {code}
                </TableCell>
                <TableCell>{details}</TableCell>
                <TableCell numeric>{severity}</TableCell>
                <TableCell>
                  {moment(time).format("Do MMM YYYY hh:mma")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

export function InfoField({ label, value, hint }) {
  if (!value || value?.length === 0) return null;

  return (
    <Grid
      item
      xs={6}
      md={3}
      title={hint}
      component={Box}
      display="flex"
      flexDirection="column"
    >
      {label ? <Typography variant="caption">{label}</Typography> : null}
      {["string", "number"].includes(typeof value) ? (
        <Typography variant="body1">{value}</Typography>
      ) : (
        value
      )}
    </Grid>
  );
}

const Title = ({ record }) => {
  return <Typography variant="h6">{record.instrument.sampleId}</Typography>;
};
