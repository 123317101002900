import raFrench from "ra-language-french";

export const french = {
  ...raFrench, // Spread French language pack

  resources: {
    results: {
      name: "Résultats", // Results in French
    },
    requests: {
      name: "Demandes", // Requests in French
    },
    users: {
      name: "Utilisateurs", // Users in French
    },
    timelines: {
      name: "Chronologie", // Timeline in French
    },
    facilities: {
      name: "Installations", // Facilities in French
    },
    machines: {
      name: "Équipement", // Equipment in French
    },
    "machine-logs": {
      name: "journaux-de-machine", // machine-logs in French
    },
    "machine-calibrations": {
      name: "Étalonnages", // Calibrations in French
    },
    maintenance: {
      name: "Demandes de service", // Service Requests in French
    },
    "relay-logs": {
      name: "journaux-de-relais", // relay-logs in French
    },
    districts: {
      name: "Géographies", // Geographies in French
    },
    levels: {
      name: "Niveaux géographiques", // Geographic Levels in French
    },
    tests: {
      name: "Tests", // Tests in French
    },
    cases: {
      name: "Cas de traitement", // Treatment Case in French
    },
    items: {
      name: "Stock", // Stock in French
    },
    adjustments: {
      name: "Ajustements de stock", // Stock Adjustments in French
    },
    stockpiles: {
      name: "Piles de stock", // Stock Piles in French
    },
    stocklevels: {
      name: "Niveaux de stock", // Stock Levels in French
    },
    "results/cartridges": {
      name: "Cartouches", // Cartridges in French
    },
    "facility-assignments": {
      name: "Liste principale", // Master List in French
    },
    "reports/dashboards": {
      name: "Tableaux de bord", // Dashboards in French
    },
    settings: {
      name: "Paramètres", // Settings in French
    },
    versions: {
      name: "Versions", // Versions in French
    },
    analyses: {
      name: "Analyses", // Analyses in French
    },
  },

  medx: {
    unitoftime: {
      day: "day",
      week: "week",
      month: "month",
      quarter: "quarter",
      year: "year",
    },
  },
};
