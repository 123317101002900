import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";

import { ChartContainer } from "./container";

import "./pie.css";
import { Conditionally } from "../../components/conditionally";
import { TrendingUp } from "@material-ui/icons";
import { useTheme } from "../../theme";

export const NumberChart = ({ request, format = (d) => d, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <ChartContainer {...props} defaultState={{ series: [] }}>
      {({ value: { series } }) => {
        return (
          <Grid container spacing={1}>
            {series.map((serie, i) => (
              <Grid item xs={6} xl={3} component={Box}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  key={i}
                  flexGrow={1}
                  margin={1}
                  alignItems={"center"}
                  {...(isSmallScreen ? { flexBasis: "33.33%" } : {})}
                >
                  <Conditionally
                    when={serie.image}
                    render={() => (
                      <Box marginRight={"8px"}>
                        <img src={serie.image} height="54" alt={serie.name} />
                      </Box>
                    )}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Conditionally
                      when={props.compare}
                      render={() => (
                        <Box display={"flex"} alignItems={"center"}>
                          <TrendingUp
                            htmlColor="green"
                            style={{ fontSize: 18 }}
                          />
                          <Box width={"4px"} />
                          <Typography
                            stlye="green"
                            variant="caption"
                            weight="700"
                          >
                            15%
                          </Typography>
                        </Box>
                      )}
                    />

                    <Typography
                      variant={"h5"}
                      style={{ fontWeight: 500, lineHeight: 1 }}
                    >
                      {serie.value}
                    </Typography>
                    <Box marginRight={1} />
                    <Typography variant="caption" color="textSecondary">
                      {serie.name}
                    </Typography>
                    <Conditionally
                      when={serie.compare}
                      render={() => (
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          alignItems={"center"}
                        >
                          <TrendingUp
                            style={{ color: "red" }}
                            fontSize="small"
                          />
                          <Box marginRight={"4px"} />
                          <Typography variant="caption" color="textSecondary">
                            2%
                          </Typography>
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        );
      }}
    </ChartContainer>
  );
};
