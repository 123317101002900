import raEnglish from "ra-language-english";

export const english = {
  ...raEnglish,

  resources: {
    results: {
      name: "Results",
    },
    requests: {
      name: "Requests",
    },
    users: {
      name: "Users",
    },
    timelines: {
      name: "Timeline",
    },
    facilities: {
      name: "Facilities",
    },
    machines: {
      name: "Equipment",
    },
    "machine-logs": {
      name: "machine-logs",
    },
    "machine-calibrations": {
      name: "Calibrations",
    },
    maintenance: {
      name: "Maintenance",
    },
    "relay-logs": {
      name: "Relay Logs",
    },
    districts: {
      name: "Geographies",
    },
    levels: {
      name: "Geographic Levels",
    },
    tests: {
      name: "Tests",
    },
    cases: {
      name: "Treatment Case",
    },
    items: {
      name: "Stock",
    },
    adjustments: {
      name: "Stock Adjustments",
    },
    stockpiles: {
      name: "Stock Piles",
    },
    stocklevels: {
      name: "Stock Levels",
    },
    "results/cartridges": {
      name: "Cartridges",
    },
    "facility-assignments": {
      name: "Master List",
    },
    "reports/dashboards": {
      name: "Dashboards",
    },
    settings: {
      name: "Settings",
    },
    versions: {
      name: "Versions",
    },
    analyses: {
      name: "Analyses",
    },
    notifications: {
      name: "Notifications",
    },
  },

  medx: {
    unitoftime: {
      day: "day  |||| %{smart_count} days",
      week: "week  |||| %{smart_count} weeks",
      month: "month |||| %{smart_count} months",
      quarter: "quarter  |||| %{smart_count} quarters",
      year: "year  |||| %{smart_count} years",
    },
  },
};
