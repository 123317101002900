import React from "react";
import {
  List,
  Filter,
  Pagination as RaPagination,
  TextField,
  SelectArrayInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { MobileListGrid } from "../components/mobile-list-grid";
import { TimestampField } from "../components/fields";
import { Chip, Grid } from "@material-ui/core";
import { StatusField } from "../components/status";
import { OverdueField } from "../components/overdue";
import { Conditionally } from "../components/conditionally";
import { issueTypes, issueTypesMapper, statuses } from "./constants";
import { DateRangeInput } from "../components/date-range";
import { admin, dho, superAdmin } from "../roles";
import { filterToLocationQuery, locationInputText, LocationOptionRenderer, matchLocationSuggestion } from "../districts/list";

export const MaintenanceList = (props) => (
  <List
    title="Maintenance Cases"
    filters={<MaintenanceFilter user={props.permissions} />}
    bulkActionButtons={false}
    perPage={50}
    pagination={<Pagination />}
    sort={{ field: "createdAt", order: "DESC" }}
    filterDefaultValues={{ suspended: false }}
    {...props}
  >
    <MaintenanceListGrid />
  </List>
);

export const MaintenanceListGrid = (props) => (
  <MobileListGrid
    {...props}
    metadata={(record) => (
      <Grid container spacing={1}>
        <Grid item>
          <Chip variant="outlined" label={`#${record.maintenanceNumber}`} />
        </Grid>

        <Grid item>
          <Chip label={issueTypesMapper[record.issueType]} variant="outlined" />
        </Grid>

        <Grid item>
          <StatusField record={record} />
        </Grid>

        <Conditionally
          when={record.status === "pending"}
          render={() => (
            <Grid item>
              <OverdueField record={record} />
            </Grid>
          )}
        />
      </Grid>
    )}
    content={(record) => {
      return <TextField variant="body1" source="lab.name" record={record} />;
    }}
    link={(record) => `/maintenance/${record.id}/show`}
    right={(record) => (
      <TimestampField record={record} label="Created" source="createdAt" />
    )}
  />
);

const Pagination = (props) => (
  <RaPagination
    rowsPerPageOptions={[10, 25, 50, 100]}
    perPage={50}
    {...props}
  />
);

const MaintenanceFilter = ({ user, ...props }) => {
  return (
    <Filter {...props}>
      <SelectArrayInput
        style={{ minWidth: "95%" }}
        source="status"
        variant="outlined"
        label="Status"
        choices={statuses}
      />
      <SelectArrayInput
        style={{ minWidth: "95%" }}
        source="issueType"
        variant="outlined"
        label="Issue Type"
        choices={issueTypes}
      />
      <ReferenceArrayInput
        label="Facility"
        style={{ minWidth: "95%" }}
        source="facilityId"
        reference="facilities"
        variant="outlined"
        filterToQuery={(name) => ({ search: { name } })}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>

      {[superAdmin, admin, dho].includes(user?.role) ? (
        <ReferenceInput
          source="districtId"
          reference="districts"
          variant="outlined"
          label="Location"
          filterToQuery={filterToLocationQuery}
        >
          <AutocompleteInput
            option
            optionText={<LocationOptionRenderer />}
            matchSuggestion={matchLocationSuggestion}
            inputText={locationInputText}
          />
        </ReferenceInput>
      ) : null}

      <DateRangeInput
        variant="outlined"
        source="dates.createdAt"
        label="Initiated Date"
      />
    </Filter>
  );
};
