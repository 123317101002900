import React from "react";
import { backendUrl } from "../config";
import { CloudDownload } from "@material-ui/icons";
import { MenuItemLink } from "react-admin";

export const Bullboard = () => {
  function handleDownload(e) {
    e.preventDefault();

    window.open(`${backendUrl}/bullboard`);
  }

  return (
    <MenuItemLink
      leftIcon={<CloudDownload />}
      to="/bullboard"
      primaryText="Queue Status"
      onClick={handleDownload}
    />
  );
};
